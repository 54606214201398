<template>
	<div>
		<div class="modal-header"></div>
		<div class="modal-body">
			<h2 class="text-center mb-5">NOTICE</h2>
			<p class="text-format-2x text-center px-3">
				Your camera is disconnected, please enable the camera permissions and restart the process
				again.
			</p>
		</div>
		<div class="modal-footer justify-content-center">
			<button
				data-test-id="confirm-button"
				@click="reload"
				class="button-confirm mb-5 txt-uc"
				type="button"
			>
				Reload
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'm-cam-permissions',

	methods: {
		reload() {
			window.document.location.reload();
		},
	},
};
</script>

<style lang="scss" scoped>
.mainBox {
	margin: 20px 0 0;
}
.p-rps {
	padding: 0 100px;
}
.callHelp {
	height: 30px;
}
.callHelp img {
	width: 32px;
	height: 26px;
	margin-right: 10px;
}
.callHelp p br {
	display: none;
}
.modal-header span {
	font-size: 37px;
	color: #c9c9c9;
}
.modal-body {
	margin: 10px 30px;
}
.modal-footer {
	border-top: none;
}
@media screen and (max-width: 950px) {
	.p-rps {
		padding: 0 50px;
	}
}
@media screen and (max-width: 620px) {
	.p-rps {
		padding: 0 20px;
	}
}
@media screen and (max-device-width: 480px) {
	img {
		width: 14px;
	}
	td {
		padding-left: 0;
	}
	.p-rps {
		padding: 0;
	}
	.button-confirm {
		width: 90%;
	}
	.callHelp p br {
		display: block;
	}
}
@media screen and (max-width: 480px) {
	img {
		width: 14px;
	}
	td {
		padding-left: 0;
	}
	.p-rps {
		padding: 0;
	}
	.button-confirm {
		width: 80%;
	}
	.callHelp p br {
		display: block;
	}
}
</style>
