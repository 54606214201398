<template>
	<div :id="dynamicId">
		<call-help></call-help>
		<app-stepper :step-number="4"></app-stepper>
		<div class="camera-container">
			<m-capture
				v-if="!errorCode"
				ref="eid"
				class="camera-fixed"
				:id-type="idType"
				:authorization="token"
				@completed="onCompleted"
				@failed="onFailed"
				@phase="onPhase"
			></m-capture>

			<div v-if="errorCode === 'Document.NotAllowed'" class="errorContainer">
				<h2 class="text-center text-format-1">
					Something has gone wrong
				</h2>
				<p class="block m-2 tf text-center">
					Make sure you are showing a Spanish DNI and try again.
				</p>
				<button
					data-test-id="retry-button"
					class="button-confirm my-5 marginB mb-sm-1"
					@click="start"
				>
					Retry
				</button>
			</div>
		</div>
		<!-- boton temporal para pasar al ultimo paso -->
		<div class="buttongroup">
			<button class="button-confirm my-5 marginB mb-sm-1" @click="onCompleted">Finish</button>
		</div>
		<app-mobile-call></app-mobile-call>
	</div>
</template>

<script>
import CallHelp from '@/components/call-help';
import AppMobileCall from '@/components/app-mobile-call';
import AppStepper from '@/components/app-stepper';
import MCapture from '@/modals/m-capture';
import MCamPermissions from '@/modals/m-cam-permissions';

const REQUIRE_DNI_TYPE = 62;

export default {
	name: 'app-camera-capture',

	components: {
		CallHelp,
		AppStepper,
		AppMobileCall,
		MCapture,
	},

	data() {
		return {
			idType: REQUIRE_DNI_TYPE,
			errorCode: '',
			dynamicId: 'app-camera-capture',
		};
	},

	computed: {
		token({
			$store: {
				state: {
					eid: { token },
				},
			},
		}) {
			return token;
		},
	},

	watch: {
		idType: {
			immediate: true,
			handler(value) {
				if (value > 0) {
					this.start();
				}
			},
		},
	},

	methods: {
		start() {
			this.errorCode = '';
			this.$nextTick(() => {
				setTimeout(() => {
					this.$refs.eid.startCapture();
				}, 100);
			});
		},

		onCompleted() {
			// this.$store.dispatch('eid/getData').then(() => {
			// 	this.$store.dispatch('onboarding/saveProcess', {}).then(() => {
			// 		this.$router.push('app-confirmation');
			// 	});
			// });
			this.$router.push('app-confirmation');
		},

		onFailed(name) {
			if (name === 'CameraPermission.Denied') {
				this.$store.dispatch('modal/open', MCamPermissions);
			} else if (name === 'Document.NotAllowed') {
				this.errorCode = 'Document.NotAllowed';
			} else if (name === 'Liveness.NotDetected') {
				// This can't be empty
			}
		},

		onPhase(phase) {
			this.dynamicId = phase === 'FACE' ? 'app-identity-identification' : 'app-camera-capture';
		},
	},
};
</script>

<style lang="scss" scoped>
.camera-fixed {
	width: 100% !important;
	max-width: 700px !important;
	margin: 0 auto !important;
	height: 400px !important;
	margin-top: 32px !important;
}

.buttongroup {
	display: flex;
	margin: 80px auto;
	justify-content: space-evenly;
}

.button {
	width: 150px;
	height: 150px;
	background: #eee;
	border-radius: 4px;
	padding: 32px;
	cursor: pointer;
}

.button img {
	width: 100%;
	height: 100%;
}

.errorContainer {
	padding: 20px;
	margin-top: 40px;
	text-align: center;
}
</style>
